<template>
  <div class="service-times-presentations template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcPlacementDates }}</h1>
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-header>
    <!-- / Page Header -->

    <page-body class="mx-lg">
      <section class="section-1">
        <div class="bg-block p-4">
          <header class="mb-5">
            <h2>{{ translations.tcAllPlacements }}</h2>
            <ToggleArrow v-if="toggle == true" @click="Section1 = !Section1" section="Section1" :status="Section1">
            </ToggleArrow>
          </header>
          <b-button v-if="Section1 && iCanSeeAny(secured_placement_date_add_controls)" variant="primary"
            @click="addPlacementDate()">{{ translations.tcAddPlacement }}</b-button>
          <b-button v-if="Section1 && convertedPlcs.length > 6" class="ml-3" variant="primary"
            @click="showAll = !showAll">
            <span v-if="!showAll">{{ translations.tcViewAllPlacements }}</span>
            <span v-else>{{ translations.tcHideExtraPlacements }}</span>
          </b-button>
          <div v-if="Section1 && translations.components" class="pres-dates d-flex flex-wrap">
            <div class="col-lg-4 col-md-4" v-for="(plc, index) in this.convertedPlcs" :key="plc.mtg_key">
              <app-event :obj="plc" :i18n="translations.components" v-if="index < 6 || showAll"
                @edit_requested="locationStorePlacementKeyAndRedirect($event)"></app-event>
            </div>
          </div>
        </div>
      </section>
    </page-body>
    <!-- / Page Body -->
    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import date_data from '@/json/date.json'
import Event from '@/components/Event.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'all-placement-dates',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      toggle: true,
      showAll: false,
      Section1: true,
      Section2: true,
      backJumpObj: {
        link: '#',
        location: '',
      },
      secured_placement_date_add_controls: {
        add_scripture_placement_facility_dates_button: '13588ae8-9c7b-4b8e-938a-e7eaa7cd7c7f',
      },
    }
  },
  methods: {
    ...mapActions({
      clearLocationDetails: 'scriptureDistribution/clearLocationDetails',
      clearPlacementDateDetails: 'scriptureDistribution/clearPlacementDateDetails',
      loadAllPlacementDatesByLocation: 'scriptureDistribution/loadAllPlacementDatesByLocation',
      loadLocationDetails: 'scriptureDistribution/loadLocationDetails',
      setLoadingStatus: 'menu/setLoadingStatus',
      setRouterBackLink: 'user/setRouterBackLink',
      setSelectedPlacementDateKey: 'user/setSelectedPlacementDateKey',
      setSelectedLocationKey: 'user/setSelectedLocationKey',
    }),
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.loadLocationDetails({ org_key: this.userSelectedLocationKey, org_type_key: this.userSelectedOrgTypeKey }),
          await this.loadAllPlacementDatesByLocation(this.userSelectedLocationKey),
        ]).then(() => {
          this.backJumpObj.location = this.location.org_name
          this.backJumpObj.link = '/programs/sd/location-profile/'
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async locationStorePlacementKeyAndRedirect(data) {
      await this.setRouterBackLink('all-placement-dates')
      await this.setSelectedPlacementDateKey(data.mtg_key)
      this.$router.push({ name: 'placement-details' })
    },
    async addPlacementDate() {
      await this.setSelectedPlacementDateKey('')
      await this.clearPlacementDateDetails('')
      await this.setRouterBackLink('all-placement-dates')
      this.$router.push('/programs/sd/add-placement-date')
    },
  },
  computed: {
    ...mapGetters({
      all_placements: 'scriptureDistribution/all_placements',
      iCanSeeAny: 'user/iCanSeeAny',
      location: 'scriptureDistribution/locationDetail',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userSelectedLocationKey: 'user/userSelectedLocationKey',
      userSelectedOrgTypeKey: 'user/userSelectedOrgTypeKey',
    }),
    months() {
      return date_data.months.map((dm) => dm.abbrlow)
    },
    convertedPlcs() {
      return this.all_placements.map((obj) => {
        let cDate = !!obj.mtg_start_date ? new Date(obj.mtg_start_date) : null
        let month = !!cDate ? this.months[cDate.getMonth()] : ''
        let day = !!cDate ? cDate.getDate() : ''
        let year = !!cDate ? cDate.getFullYear() : ''
        let fullDate = !!obj.mtg_start_date ? obj.mtg_start_date : ''
        let rObj = {
          mtg_key: obj.mtg_key,
          mtg_start_date: obj.mtg_start_date,
          member_type: obj.member_type,
          coordinator_ind_key: obj.mtg_coordinator_ind_key,
          coordinator: obj.mtg_coordinator_name,
          member_type: obj.member_type,
          month: month,
          day: day,
          year: year,
          fullDate: fullDate,
          editLink: true,
        }
        return rObj
      })
    },
  },
  async created() {
    if (!this.userSelectedLocationKey) {
      this.$router.push('/programs/sd/scripture-distribution/')
    }
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('event', 'back-jump'),
      this.pageLoad()
    ]).then((results) => {
      const translatedText = { ...results[1] }
      this.$set(this.translations, 'components', translatedText)
    }
    )
  },
  components: {
    appEvent: Event,
    BackJump: BackJump,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';

.service-times-presentations {
  header h1 {
    padding-bottom: 40px;

    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }

  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;

    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;

      svg path {
        fill: #000;
      }
    }
  }

  section {
    margin-bottom: 20px;

    .p-4 {
      padding: 20px 30px 20px 30px !important;

      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }

    header {
      display: flex;

      h2 {
        flex: 1 1 auto;

        @include breakpoint(sm) {
          margin-bottom: 10px;
        }
      }

      .toggle-arrow {
        align-self: flex-start;
        margin-top: 5px;
      }
    }

    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .service-times,
  .pres-dates {
    margin-top: 35px;
  }

  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.event {
  margin-bottom: 10px;
  border: 1px solid #e9e9e9;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

  h3 {
    color: $teal-800;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }
}
</style>
